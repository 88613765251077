import React, { useEffect, useState, useLayoutEffect } from 'react';
import './about_app.scss';
import ScrollAnimation from 'react-scroll-animation';
import { Consumer } from '../../context';

export default function AboutApp(props) {
    const [visible, setVisible] = useState(false);

    const [basicInfo, setBasicInfo] = useState([{
        homeConfigAboutTitle: '',
        homeConfigAboutContent: '',
    }]);
    useEffect(() => {
        if (props.loading) {
            if (props.data.data.data?.basicInfo) {

                const arr = [];
                for (let i = 0; i < props.data.data.data?.basicInfo.length; i++) {
                    const v = props.data.data.data?.basicInfo[i];
                    arr.push({ homeConfigAboutTitle: v.content.homeConfigAboutTitle, homeConfigAboutContent: v.content.homeConfigAboutContent, saasThumbnail: v.content.saasThumbnail });
                }
                setBasicInfo(v => arr);
            } else {
                setBasicInfo(v => [{
                    homeConfigAboutTitle: 'xxxx',
                    homeConfigAboutContent: 'xxx',
                }]);

            }
        }
    }, [props.loading]);

    function onWindowScroll(e) {
        const top = document.documentElement.scrollTop;
        // console.log(top);
        if (top > 2100) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', onWindowScroll);
        return () => window.removeEventListener('scroll', onWindowScroll);
    }, []);



    return (
        <>
            <Consumer>
                {
                    value =>
                        <div className="about_app" id='about_app'>
                            <ScrollAnimation animateIn="fadeInUp" offset={-400}>
                                <div className='trims_container'>
                                    <h2>关于我们</h2>
                                    <div className='trims_app'></div>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeIn">
                                <div className="about_bg" id='about_bg' style={{ display: visible ? 'block' : 'none' }}>
                                    <div className='about_container'>
                                        <div className="about_content">
                                            <p>{props.loading ? basicInfo[0].homeConfigAboutTitle : '易道助邦科技有限公司是一家于2022年创立的新兴企业。我们专注于提供广泛的技术服务和解决方案，以满足客户在不断发展的数字化时代中的需求。'}</p>
                                            <br />
                                            <div>
                                                {
                                                    props.loading ? basicInfo[0].homeConfigAboutContent
                                                        :
                                                        `作为一家技术驱动型公司，我们的经营范围涵盖了技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广等领域。我们拥有一支充满激情和经验丰富的团队，致力于为客户提供创新的解决方案和高质量的服务。
                                                    <br />
                                                    <br />
                                                    在软件开发方面，我们专注于人工智能应用软件开发、网络与信息安全软件开发等领域。我们深入了解客户的需求，并结合最新的技术趋势和行业标准，为客户量身定制高效、可靠的软件解决方案。
                                                    <br />
                                                    <br />
                                                    此外，我们还提供专业设计服务，包括信息系统运行维护服务、物联网技术服务和企业管理咨询等。我们致力于帮助客户提升运营效率、降低成本，并在竞争激烈的市场中获取竞争优势。
                                                    <br />
                                                    <br />
                                                    我们的产品包括劳务代理和劳务派遣。通过与各行各业的企业建立合作关系，我们为客户提供高质量的劳务服务，帮助他们解决人力资源管理方面的挑战。
                                                    <br />
                                                    <br />
                                                    易道助邦科技有限公司致力于成为客户信赖的技术合作伙伴。我们通过持续创新和不断提升服务质量，与客户共同实现业务目标，推动数字化转型和技术进步。如果您对我们的公司感兴趣或有任何合作需求，请随时与我们联系。我们期待与您携手合作，共创美好未来。
                                                `
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>
                }
            </Consumer>
        </>
    );
}
