import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.scss';
import logo from './assets/icons/ydzb.png';

// pc
import Layout from './layout';
import Home from './pages/home/home';
import NewsDetail from './pages/newsDetail/newsDetail';
import NewsList from './pages/newsList/newsList';
import Platform from './pages/platform/platform';

// app
import LayoutApp from './layout_app';
import HomeApp from './pages/home_app/home_app';
import NewsParticularsApp from './pages/news_particulars_app/news_particulars_app';
import NewsListApp from './pages/newsList_app/newsList_app';

// 判断是否是PC、手机端
function os() {
  var ua = navigator.userAgent,
    isWindowsPhone = /(?:Windows Phone)/.test(ua),
    isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
    isAndroid = /(?:Android)/.test(ua),
    isFireFox = /(?:Firefox)/.test(ua),
    isChrome = /(?:Chrome|CriOS)/.test(ua),
    isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
    isPhone = /(?:iPhone)/.test(ua) && !isTablet,
    isPc = !isPhone && !isAndroid && !isSymbian;

  return {
    isTablet: isTablet,
    isPhone: isPhone,
    isAndroid: isAndroid,
    isPc: isPc,
    isChrome: isChrome,
  };
};


function App() {

  const [device, setDevice] = useState(false);
  const [loading, setLoading] = useState(true);

  const onSetFalseLoading = () => {
    const timer = setTimeout(function () {
      setLoading(v => false);
      const id = document.getElementById('home-loading');
      if (id) {
        id.classList.add('animate__animated');
        id.classList.add('animate__fadeOut');
        id.classList.add('home-loading-clear');
      }
    }, 1500);
  };

  useEffect(() => {
    const oos = os();
    // pc时加载一套页面
    if (oos.isPc) {
      setDevice(data => false);
    }
    // 移动端时加载另外一套页面 
    if (oos.isAndroid || oos.isPhone) {
      setDevice(data => true);
    } else if (oos.isTablet) {
      setDevice(data => true);
    }
  }, []);

  return (
    <div className="App">
      <HomeLoading device={device} />
      {
        device || <Layout>
          <Route path={'/'} element={<Home onSetFalseLoading={onSetFalseLoading} />}></Route>;
          <Route path={'/newsDetail'} element={<NewsDetail onSetFalseLoading={onSetFalseLoading} />}></Route>;
          <Route path={'/newsList'} element={<NewsList onSetFalseLoading={onSetFalseLoading} />}></Route>;
          <Route path={'/platform'} element={<Platform onSetFalseLoading={onSetFalseLoading} />}></Route>;
        </Layout>
      }
      {
        !device || <LayoutApp>
          <Routes>
            <Route path={'/'} element={<HomeApp />}></Route>;
            <Route path={'/newsParticularsApp'} element={<NewsParticularsApp />}></Route>;
            <Route path={'/NewsListApp'} element={<NewsListApp />}></Route>;
          </Routes>
        </LayoutApp>
      }
    </div>
  );
}


// 首页加载动画
function HomeLoading(props) {
  //  animate__animated animate__fadeOut	消失动画
  return (
    <>
      {
        props.device || (
          <div id='home-loading' className='home-loading'>
            <div className='container'>
              <div className='logo-container'>
                <img className='homeLogo' src={logo} alt='易道助邦' />
                <h1>易道助邦</h1>
              </div>
              <div className='svg-container'>
                <svg className='svg1' viewBox="0 0 1440 320"><path fill="#85b1fd" d="M0,256L120,213.3C240,171,480,85,720,58.7C960,32,1200,64,1320,80L1440,96L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
                <svg className='svg2' viewBox="0 0 1440 320"><path fill="#0e66fb" d="M0,96L120,133.3C240,171,480,245,720,266.7C960,288,1200,256,1320,240L1440,224L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
                <svg className='svg3' viewBox="0 0 1440 320"><path fill="#bdd5ff" d="M0,96L120,90.7C240,85,480,75,720,101.3C960,128,1200,192,1320,224L1440,256L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}

export default App;
