import React, { useEffect, useState, useLayoutEffect } from 'react';
import './home_app.scss';
import ScrollAnimation from 'react-scroll-animation';
import home1 from '../../assets/img/home1.jpg';
import home2 from '../../assets/img/home2.jpg';
import home3 from '../../assets/img/home3.jpg';
import home5 from '../../assets/img/home5.jpg';
import home6 from '../../assets/img/home6.jpg';
import home7 from '../../assets/img/home7.jpg';
import td from '../../assets/img/td.png';

import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';

import ProductsApp from '../products_app/products_app';
import NewsApp from '../news_app/news_app';
import AboutApp from '../about_app/about_app';

import saas1 from '../../assets/img/saas1.jpg';
import saas2 from '../../assets/img/saas2.jpg';

import ewm from '../../assets/img/ewm.jpg';

import { getHomeAll, getHomePage, getSass, getService } from '../service';
import { Consumer, Provider } from '../../context';
SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function Home() {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	useLayoutEffect(() => {

		const fetch = async () => {
			const res = await getHomeAll();
			setData(res);
			setLoading(true);
		};
		fetch();
	}, []);
	return (
		<>
			<Provider value={data}>
				<HomeSwiper loading={loading} data={data} />
				<SaasApp loading={loading} data={data} />
				<ServiceApp loading={loading} data={data} />
				<ProductsApp loading={loading} data={data} />
				<NewsApp loading={loading} data={data} />
				<AboutApp loading={loading} data={data} />
				<Contact loading={loading} data={data} />
			</Provider>
		</>
	);
}

function HomeSwiper() {
	const slides = [
		{ id: 1, imageUrl: td, homeConfigBannerTitle1: '我们专注制作人力资源平台', homeConfigBannerTitle2: '' },
		{ id: 2, imageUrl: home2, homeConfigBannerTitle1: '人力资源代理', homeConfigBannerTitle2: '' },
		{ id: 3, imageUrl: home3, homeConfigBannerTitle1: '人力资源派遣', homeConfigBannerTitle2: '' },
		{ id: 5, imageUrl: home5, homeConfigBannerTitle1: '社会化用工服务', homeConfigBannerTitle2: '' },
		{ id: 6, imageUrl: home6, homeConfigBannerTitle1: '劳资法律服务系统', homeConfigBannerTitle2: '' },
		{ id: 7, imageUrl: home7, homeConfigBannerTitle1: '职业技能培训系统', homeConfigBannerTitle2: '' },
	];

	return (
		<>
			<div style={{
				height: '4rem',
			}}></div>
			<div className='home_app' id='home_app'>
				<Swiper
					style={{ height: '100%' }}
					className="swiper-container"
					spaceBetween={0}
					slidesPerView={1}
					effect="fade" // 设置淡入淡出效果
					autoplay={{ delay: 4000, disableOnInteraction: false }}
					loop
				>
					{slides.map((slide, index) => (
						<SwiperSlide key={slide.id}>
							<div className="h_slide_item_app">
								<div className="it_content_app">
									<img className='it_img_app' src={slide.imageUrl} alt={`Slide ${slide.id}`} />
									{
										slide.homeConfigBannerTitle1 ?
											<h1 className='it-title1 animate__animated animate__zoomIn'>{slide.homeConfigBannerTitle1}</h1>
											: null
									}
									{
										slide.homeConfigBannerTitle2 ?
											<h1 className='it-title2 animate__animated animate__zoomIn'>{slide.homeConfigBannerTitle2}</h1>
											: null
									}

									{index === 0 ?
										<div className='it-products'>
											<div className='pd-flex'>
												<div className='fx fx1 animate__animated animate__fadeInRight'><span> 平台 </span></div>
												<div className='fx fx2 animate__animated animate__fadeInRight'><span> 平台 </span></div>
												<div className='fx fx3 animate__animated animate__fadeInRight'><span> 平台 </span></div>
												<div className='fx fx4 animate__animated animate__fadeInRight'><span> 平台 </span></div>
												<div className='fx fx5 animate__animated animate__fadeInRight'><span> 平台 </span></div>
												<div className='fx fx6 animate__animated animate__fadeInRight'><span> 平台 </span></div>
											</div>
										</div>
										: null}
								</div>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</>
	);
}

function SaasApp(props) {
	const [saasService, setSaasService] = useState([{
		saasTitle: '',
		saasContent: '',
		saasThumbnail: '',
	}, {
		saasTitle: '',
		saasContent: '',
		saasThumbnail: '',
	}]);

	useEffect(() => {
		if (props.loading) {

			if (props.data.data.data?.saasService) {
				const arr = [];
				for (let i = 0; i < props.data.data.data?.saasService.length; i++) {
					const v = props.data.data.data?.saasService[i];
					arr.push({ saasTitle: v.content.saasTitle, saasContent: v.content.saasContent, saasThumbnail: v.content.saasThumbnail });
				}

				setSaasService(v => arr);
			} else {
				setSaasService(v => [{
					saasTitle: '',
					saasContent: '',
					saasThumbnail: '',
				}, {
					saasTitle: '',
					saasContent: '',
					saasThumbnail: '',
				}]);

			}
		}
	}, [props.loading]);

	return (
		<>
			<Consumer>
				{value =>
					<div className="saas_app">
						<div className='trims_container animate__animated animate__fadeInUp'>
							<h2>数字化SaaS服务</h2>
							<div className='trims_app'></div>
						</div>
						<div className='saas_container'>
							<div className='saas_card animate__animated animate__fadeInLeft'>
								<div className="card_left">
									<span className='title'>{props.loading ? saasService[0].saasTitle : '自研人事劳务代理管理系统'}</span>
									<br />
									<br />
									<div className='body'>
										{props.loading ? saasService[0].saasContent : `在线完成五险、公积金、薪酬、个税等服务，服务记录全程可视，实现互动，交付，计费全流程闭环，让企业&员工乐享更高效、准确履约交付和极致体验。`}
									</div>
								</div>
								<div className="card_right">
									<img className='ct-img' src={React.api.base_url + saasService[0].saasThumbnail} alt='saas1' />
								</div>
							</div>
							<div className='saas_card animate__animated animate__fadeInRight'>
								<div className="card_right2">
									<img className='ct-img' src={React.api.base_url + saasService[1].saasThumbnail} alt='saas1' />
								</div>
								<div className="card_left">
									<span className='title'>{props.loading ? saasService[1].saasTitle : '自研人事劳务派遣管理系统'}</span>
									<br />
									<br />
									<div className='body'>
										{props.loading ? saasService[1].saasContent : `在线完成五险、公积金、薪酬、个税等服务，服务记录全程可视，实现互动，交付，计费全流程闭环，让企业&员工乐享更高效、准确履约交付和极致体验。`}
									</div>
								</div>
							</div>
						</div>
					</div>
				}
			</Consumer>
		</>
	);
}


function ServiceApp(props) {
	const [serviceIntroduce, setServiceIntroduce] = useState([{
		serviceIcon: '',
		serviceContent: '',
	}]);

	useEffect(() => {
		if (props.loading) {

			if (props.data.data.data?.serviceIntroduce) {
				const arr = [];
				for (let i = 0; i < props.data.data.data?.serviceIntroduce.length; i++) {
					const v = props.data.data.data?.serviceIntroduce[i];
					arr.push({ serviceIcon: v.content.serviceIcon, serviceContent: v.content.serviceContent });
				}
				setServiceIntroduce(v => arr);
			} else {
				setServiceIntroduce(v => []);
			}
		}
	}, [props.loading]);
	return (
		<>
			<Consumer>
				{
					value =>
						<div className="service_app">
							<ScrollAnimation animateIn="fadeInUp" offset={-400}>
								<div className='trims_container'>
									<h2>易道助邦能为你做什么?</h2>
									<div className='trims_app'></div>
								</div>
							</ScrollAnimation>
							<div className='service_container'>
								<div className='service_card'>
									{
										props.loading ? serviceIntroduce.map((v, index) => (
											<ScrollAnimation animateIn="bounceIn" key={index} delay={index * 1000} offset={-450}>
												<div className='card_item' >
													<img className='item_icon' src={React.api.base_url + v.serviceIcon} alt='saas1' />
													{/* <div className='item_icon' dangerouslySetInnerHTML={{ __html: item.icon }}>
													</div> */}
													<div className='item_body'>
														<p>{v.serviceContent}</p>
													</div>
												</div>
											</ScrollAnimation>
										))
											: ''
									}
								</div>
							</div>
						</div >
				}
			</Consumer>
		</>
	);

}


function Contact(props) {

	const [contact, setContact] = useState([{
		homeConfigEwm: ewm,
		homeConfigEwmText: '微信公众号',
		homeConfigHotline: 'xxx-xxxx-xxx',	// 服务热线
		homeConfigPhone: 'xxx-xxx', // 电话
		homeConfigFax: 'xxx-xxx', // 传真电话
		homeConfigEmail: 'xxxxx', // 邮箱
		homeConfigAddress: 'xxxxxx', // 地址
		// 山西省阳泉高新技术产业开发区大连街山西智创城NO.7云谷科技创新园C座9层902室
	}]);

	useEffect(() => {
		if (props.loading) {
			if (props.data.data.data?.basicInfo) {

				const arr = [];
				for (let i = 0; i < props.data.data.data?.basicInfo.length; i++) {
					const v = props.data.data.data?.basicInfo[i];
					arr.push({
						homeConfigEwmText: v.content.homeConfigEwmText,
						homeConfigEwm: v.content.homeConfigEwm,
						homeConfigHotline: v.content.homeConfigHotline,
						homeConfigPhone: v.content.homeConfigPhone,
						homeConfigFax: v.content.homeConfigFax,
						homeConfigEmail: v.content.homeConfigEmail,
						homeConfigAddress: v.content.homeConfigAddress,
					});
				}
				setContact(v => arr);
			} else {
				setContact(v => [{
					homeConfigEwm: ewm,
					homeConfigEwmText: '微信公众号',
					homeConfigHotline: 'xxx-xxxx-xxx',	// 服务热线
					homeConfigPhone: 'xxx-xxx', // 电话
					homeConfigFax: 'xxx-xxx', // 传真电话
					homeConfigEmail: 'xxxxx', // 邮箱
					homeConfigAddress: 'xxxxxx', // 地址
				}]);

			}
		}
	}, [props.loading]);

	return (
		<>
			<Consumer>
				{
					value =>
						<div className="contact_app">
							<ScrollAnimation animateIn="fadeInUp" offset={-500}>
								<div className='trims_container'>
									<h2>联系我们</h2>
									<div className='trims_app'></div>
								</div>
							</ScrollAnimation>
							<div className='contact_container' >
								<div className='contact_card'>
									{
										props.loading ?
											<div className='contact_us'>
												<img className='ewm' src={React.api.base_url + contact[0].homeConfigEwm} alt='ewm' />
												<div className='content'>
													<p>服务热线：{contact[0].homeConfigHotline}</p>
													<p>电话：{contact[0].homeConfigPhone}</p>
													<p>传真：{contact[0].homeConfigFax}</p>
													<p>邮箱：{contact[0].homeConfigEmail}</p>
													<p>地址：{contact[0].homeConfigAddress}</p>
												</div>
											</div>
											:
											<div className='contact_us'>
												<div className='QR'>
													<img className='ewm' src={contact.img} alt='ewm' />
												</div>
												<div className='content'>
													<p>服务热线：{contact.hotline}</p>
													<p>电话：{contact.phone}</p>
													<p>传真：{contact.fax}</p>
													<p>邮箱：{contact.email}</p>
													<p>地址：{contact.address}</p>
												</div>
											</div>
									}
								</div>
							</div>
						</div>
				}
			</Consumer>
		</>
	);
}


