import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';

import 'animate.css';
import api from './api';
import { BrowserRouter as Router } from 'react-router-dom';

export const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <Router>
    <App />
  </Router>
  // </React.StrictMode>
);

// api接口
React.api = api;
