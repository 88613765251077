import React, { useEffect, useLayoutEffect, useState } from 'react';
import './home.scss';
import ScrollAnimation from 'react-scroll-animation';
import home1 from '../../assets/img/home1.jpg';
import home2 from '../../assets/img/home2.jpg';
import home3 from '../../assets/img/home3.jpg';
import home4 from '../../assets/img/home4.jpg';
import home5 from '../../assets/img/home5.jpg';
import home6 from '../../assets/img/home6.jpg';
import home7 from '../../assets/img/home7.jpg';
// import saas1 from '../../assets/img/saas1.jpg';
// import saas2 from '../../assets/img/saas2.jpg';
import td from '../../assets/img/td.png';

/* 其他页面 */
import Products from '../../pages/products/products';
import News from '../../pages/news/news';
import About from '../../pages/about/about';

/* swiper */
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

/* 接口 */
import { getHomeAll } from '../service';

/* 上下文 */
import { Consumer, Provider } from '../../context';
import logo from '../../assets/icons/ydzb.png';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function Home(props) {

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	useLayoutEffect(() => {
		const fetch = async () => {
			const res = await getHomeAll();
			setData(v => res);
			setLoading(v => true);
			props.onSetFalseLoading();
		};
		fetch();
	}, []);

	return (
		<>
			<Provider value={data}>
				<HomeSwiper loading={loading} data={data} />
				<Saas loading={loading} data={data} />
				<Service loading={loading} data={data} />
				<Products loading={loading} data={data} />
				<News loading={loading} data={data} />
				<About loading={loading} data={data} />
			</Provider>
		</>
	);
}

// 首页加载动画
function HomeLoading(props) {
	//  animate__animated animate__fadeOut	消失动画

	useEffect(() => {
		if (props.loading) {
			const id = document.getElementById('home-loading');
			id.classList.add('animate__animated');
			id.classList.add('animate__fadeOut');
		}
	}, [props.loading]);

	return (
		<>
			<div id='home-loading' className='home-loading'>
				<div className='container'>
					<div className='logo-container'>
						<img className='homeLogo' src={logo} alt='易道助邦' />
						<h1>易道助邦</h1>
					</div>
					<div className='svg-container'>
						<svg className='svg1' viewBox="0 0 1440 320"><path fill="#85b1fd" d="M0,256L120,213.3C240,171,480,85,720,58.7C960,32,1200,64,1320,80L1440,96L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
						<svg className='svg2' viewBox="0 0 1440 320"><path fill="#0e66fb" d="M0,96L120,133.3C240,171,480,245,720,266.7C960,288,1200,256,1320,240L1440,224L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
						<svg className='svg3' viewBox="0 0 1440 320"><path fill="#bdd5ff" d="M0,96L120,90.7C240,85,480,75,720,101.3C960,128,1200,192,1320,224L1440,256L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
					</div>
				</div>
			</div >
		</>
	);
}

function HomeSwiper(props) {
	const [slides, setSlides] = useState([
		{ id: 1, bannerUrl: td, bannerTitle: '我们专注制作人力资源平台', bannerSubTitle: '' },
		{ id: 2, bannerUrl: home2, bannerTitle: '人力资源代理', bannerSubTitle: '' },
		{ id: 3, bannerUrl: home3, bannerTitle: '人力资源派遣', bannerSubTitle: '' },
		{ id: 4, bannerUrl: home4, bannerTitle: '职业介绍系统', bannerSubTitle: '' },
		{ id: 5, bannerUrl: home5, bannerTitle: '社会化用工服务', bannerSubTitle: '' },
		{ id: 6, bannerUrl: home6, bannerTitle: '劳资法律服务系统', bannerSubTitle: '' },
		{ id: 7, bannerUrl: home7, bannerTitle: '职业技能培训系统', bannerSubTitle: '' },
	]);

	const [sixPlatform, setSixPlatform] = useState([]);

	useEffect(() => {
		if (props.loading) {
			let arr = [];
			if (props.data.data.data?.basicInfo) {
				if (props.data.data.data?.basicInfo[0].content.homeConfigBanner.length > 0 && props.data.data.data?.basicInfo[0].content.homeConfigBannerTitle.length > 0) {
					let bn = props.data.data.data?.basicInfo[0].content.homeConfigBanner ? JSON.parse(props.data.data.data?.basicInfo[0].content.homeConfigBanner) : [];
					let bnt = props.data.data.data?.basicInfo[0].content.homeConfigBannerTitle ? JSON.parse(props.data.data.data?.basicInfo[0].content.homeConfigBannerTitle) : [];
					for (let i = 0; i < bn.length; i++) {
						arr.push({ ...bn[i], ...bnt[i] });
					}
					setSlides(v => arr);
				} else {
				}
			} else {
				setSlides(v => [
					{ id: 1, bannerUrl: td, bannerTitle: '我们专注制作人力资源平台', bannerSubTitle: '' },
					{ id: 2, bannerUrl: home2, bannerTitle: '人力资源代理', bannerSubTitle: '' },
					{ id: 3, bannerUrl: home3, bannerTitle: '人力资源派遣', bannerSubTitle: '' },
					{ id: 4, bannerUrl: home4, bannerTitle: '职业介绍系统', bannerSubTitle: '' },
					{ id: 5, bannerUrl: home5, bannerTitle: '社会化用工服务', bannerSubTitle: '' },
					{ id: 6, bannerUrl: home6, bannerTitle: '劳资法律服务系统', bannerSubTitle: '' },
					{ id: 7, bannerUrl: home7, bannerTitle: '职业技能培训系统', bannerSubTitle: '' },
				]);
			}

			let arr2 = [];
			if (props.data.data.data?.homePagePlatformEntry) {
				if (props.data.data.data?.homePagePlatformEntry.length > 0) {
					const bn = props.data.data.data?.homePagePlatformEntry;
					for (let i = 0; i < bn.length; i++) {
						arr2.push({ title: bn[i].content.homePagePlatformEntryName, url: bn[i].content.homePagePlatformEntryUrl });
					}
					setSixPlatform(v => arr2);
				}
			} else {
				for (let i = 1; i < 6; i++) {
					arr2.push({ title: `平台${i}`, url: '#' });
				}
				setSixPlatform(v => arr2);
			}

		}
	}, [props.loading]);

	const onClickPlatform = (url) => {
		window.open(url);
	};
	return (
		<>
			<Consumer>
				{
					value =>
						<>
							<div style={{
								height: '80px',
							}}></div>
							<div className='home' id='home'>
								<Swiper
									style={{ height: '100%' }}
									spaceBetween={0}
									slidesPerView={1}
									effect="fade" // 设置淡入淡出效果
									navigation
									pagination={{ clickable: true }}
									autoplay={{ delay: 4000, disableOnInteraction: false }}
									loop
								>
									{slides.map((slide, index) => (
										<SwiperSlide key={index}>
											<div className="h-slide-item">
												<div className='it-content'>
													<img className='it-img' src={React.api.base_url + slide.bannerUrl} alt={`Slide ${slide.bannerTitle + index}`} />
													{
														props.loading ? slide.bannerTitle ?
															<h1 className='it-title1 animate__animated animate__zoomIn'>{slide.bannerTitle}</h1>
															: null
															: slide.bannerTitle
													}
													{
														props.loading ? slide.bannerSubTitle ?
															<h1 className='it-title2 animate__animated animate__zoomIn'>{slide.bannerSubTitle}</h1>
															: null
															: slide.bannerTitle
													}

													{index === 0 ?
														<div className='it-products'>
															<div className='pd-flex'>
																{
																	sixPlatform.map((v, i) => (
																		<div key={i} className={`fx fx${i + 1} animate__animated animate__fadeInRight`} onClick={() => onClickPlatform(v.url)}><span> {v.title} </span></div>
																	))
																}
															</div>
														</div>
														: null}

												</div>
											</div>
										</SwiperSlide>
									))}
								</Swiper>
							</div>
						</>
				}
			</Consumer>
		</>
	);
}


function Saas(props) {

	const [saasService, setSaasService] = useState([{
		saasTitle: '',
		saasContent: '',
		saasThumbnail: '',
	}, {
		saasTitle: '',
		saasContent: '',
		saasThumbnail: '',
	}]);

	useEffect(() => {
		if (props.loading) {

			if (props.data.data.data?.saasService) {
				const arr = [];
				for (let i = 0; i < props.data.data.data?.saasService.length; i++) {
					const v = props.data.data.data?.saasService[i];
					arr.push({ saasTitle: v.content.saasTitle, saasContent: v.content.saasContent, saasThumbnail: v.content.saasThumbnail });
				}

				setSaasService(v => arr);
			} else {
				setSaasService(v => [{
					saasTitle: '',
					saasContent: '',
					saasThumbnail: '',
				}, {
					saasTitle: '',
					saasContent: '',
					saasThumbnail: '',
				}]);

			}
		}
	}, [props.loading]);

	return (
		<>
			<Consumer>
				{value =>
					<div className='saas'>
						<div className='container pc-padding'>
							<ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut" animateOnce="true" offset={-400}  >
								<div className='trims-container'>
									<div className='trims'></div>
									<h1>数字化<span className='ss-sass1'>SaaS</span>服务</h1>
								</div>
							</ScrollAnimation>
							<div className='ss-content'>
								<div className='ct-left'>
									<ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut" animateOnce="true" offset={-400} >
										<span className='ct-title'>{props.loading ? saasService[0]?.saasTitle : '自研人事劳务代理管理系统'}</span>
										<div className='ct-body'>
											{props.loading ? saasService[0]?.saasContent : `在线完成五险、公积金、薪酬、个税等服务，服务记录全程可视，实现互动，交付，计费全流程闭环，让企业&员工乐享更高效、准确履约交付和极致体验。`}
										</div>
										{/* <span className='ct-title'>自研人事劳务代理管理系统</span>
										<div className='ct-body'>
											在线完成五险、公积金、薪酬、个税等服务，服务记录全程可视，实现互动，交付，计费全流程闭环，让企业&员工乐享更高效、准确履约交付和极致体验。
										</div> */}
									</ScrollAnimation>
								</div>
								<div className='ct-right'>
									<ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut" animateOnce="true" offset={-400}  >
										{/* saasThumbnail */}
										<img className='ct-img' src={React.api.base_url + saasService[0]?.saasThumbnail} alt='saas1' />
										{/* <img className='ct-img' src={saas1} alt='saas1' /> */}
									</ScrollAnimation>
								</div>
							</div>
							<div className='ss-content2'>
								<div className='ct-left'>
									<ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut" animateOnce="true" offset={-400}  >
										<img className='ct-img' src={React.api.base_url + saasService[1]?.saasThumbnail} alt='saas2' />
										{/* <img className='ct-img' src={saas2} alt='saas2' /> */}
									</ScrollAnimation>
								</div>
								<div className='ct-right'>
									<ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut" animateOnce="true" offset={-400}  >
										<span className='ct-title'>{props.loading ? saasService[1]?.saasTitle : '自研人事劳务派遣管理系统'}</span>
										<div className='ct-body'>
											{props.loading ? saasService[1]?.saasContent : `在线完成五险、公积金、薪酬、个税等服务，服务记录全程可视，实现互动，交付，计费全流程闭环，让企业&员工乐享更高效、准确履约交付和极致体验。`}
										</div>
										{/* <span className='ct-title'>自研人事劳务派遣管理系统</span>
										<div className='ct-body'>
											在线完成五险、公积金、薪酬、个税等服务，服务记录全程可视，实现互动，交付，计费全流程闭环，让企业&员工乐享更高效、准确履约交付和极致体验。
										</div> */}
									</ScrollAnimation>
								</div>
							</div>

						</div>
					</div>
				}
			</Consumer>

		</>
	);
}

function Service(props) {
	const [serviceIntroduce, setServiceIntroduce] = useState([{
		serviceIcon: '',
		serviceContent: '',
	}]);

	useEffect(() => {
		if (props.loading) {

			if (props.data.data.data?.serviceIntroduce) {
				const arr = [];
				for (let i = 0; i < props.data.data.data?.serviceIntroduce.length; i++) {
					const v = props.data.data.data?.serviceIntroduce[i];
					arr.push({ serviceIcon: v.content.serviceIcon, serviceContent: v.content.serviceContent });

				}
				setServiceIntroduce(v => arr);
			} else {
				setServiceIntroduce(v => []);
			}
		}
	}, [props.loading]);

	return (
		<>
			<Consumer>
				{value =>
					<div className='service'>
						<div className='container pc-padding'>
							<ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut" animateOnce="true" offset={-400} >
								<div className='trims-container'>
									<div className='trims2'></div>
									<h1>易道助邦能为你做什么？</h1>
								</div>
							</ScrollAnimation>

							<div className='sc-content'>
								{
									props.loading ? serviceIntroduce.map((v, i) => (
										<ScrollAnimation key={i} delay={i * 1000} animateIn="fadeInUp" animateOut="fadeOut" offset={-400} >
											<div className='ct-card'>

												<img style={{ width: '2.4rem' }} src={React.api.base_url + v.serviceIcon} alt='saas1' />
												{/* <div dangerouslySetInnerHTML={{ __html: v.content.serviceIcon }}>
												</div> */}
												<div className='ct-body'>
													<p>{v.serviceContent}</p>
												</div>
											</div>
										</ScrollAnimation>
									)) : ''
								}
							</div>
							{/* 
							<div className='sc-content'>
								{
									serList.map((v, i) => (
										<ScrollAnimation key={i} delay={i * 1000} animateIn="fadeInUp" animateOut="fadeOut" offset={-400} >
											<div className='ct-card'>
												<div dangerouslySetInnerHTML={{ __html: v.icon }}>
												</div>
												<div className='ct-body'>
													<p>{v.content}</p>
												</div>
											</div>
										</ScrollAnimation>
									))
								}
							</div> */}
						</div>
					</div>
				}
			</Consumer>
		</>
	);
}
