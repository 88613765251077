import React, { Component } from 'react';
import HeaderApp from './modules/header_app';
import FooterApp from './modules/footer_app';

export default class Layout extends Component {
	render() {
		return (
			<>
				<HeaderApp />
				{this.props.children}
				<FooterApp />
			</>
		);
	}
}
