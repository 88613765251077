import React, { useEffect, useMemo, useState } from 'react';
import './products.scss';
import ScrollAnimation from 'react-scroll-animation';
import home1 from '../../assets/img/home1.jpg';
import home2 from '../../assets/img/home2.jpg';

// 清楚所有ps-num-checked样式
function clearStyle() {
	let classList = document.getElementsByClassName('ps-num-checked');
	for (const item in classList) {
		if (Object.hasOwnProperty.call(classList, item)) {
			const element = classList[item];
			element.classList.remove('ps-num-checked');
		}
	}
}

export default function Products(props) {

	const [psi, setPsi] = useState(0);

	const [productsList, setProductsList] = useState([
		{
			productId: 1,
			productThumbnail: home1,
			productName: '劳务代理',
			productInfo: '劳务代理系统是一个集成了员工管理、合同管理、社保费用管理、备案管理和五险报表生成等功能的系统。它能帮助企业或人力资源代理机构更高效地管理劳务代理业务，包括员工档案、合同续签、社保费用计算、备案管理和五险报表生成等。通过使用该系统，用户可以提高劳务代理业务的管理效率和准确性，实现更好的业务运营和发展。',
			productUrl: `http://120.48.181.47:8023`,
		},
		{
			productId: 2,
			productThumbnail: home2,
			productName: '劳务派遣',
			productInfo: '劳务派遣是一种灵活的人力资源管理方式，通过与劳务代理机构合作，企业可以将一部分员工的招聘、管理和福利等工作外包出去，从而减轻企业的人力资源负担。劳务派遣系统集成了员工管理、合同管理、社保费用管理、备案管理和五险报表生成等功能，帮助企业更高效地管理劳务派遣业务。通过使用该系统，企业可以提高劳务派遣业务的管理效率和准确性，实现更好的业务运营和发展。',
			productUrl: `http://120.48.181.47:8022`,
		}
	]);
	useEffect(() => {
		if (props.loading) {
			if (props.data.data.data?.productPresentaion) {

				const arr = [];
				for (let i = 0; i < props.data.data.data?.productPresentaion.length; i++) {
					const v = props.data.data.data?.productPresentaion[i];
					arr.push(v.content);
				}
				setProductsList(arr);

			} else {
				setProductsList([
					{
						productId: 1,
						productThumbnail: home1,
						productName: '劳务代理',
						productInfo: '劳务代理系统是一个集成了员工管理、合同管理、社保费用管理、备案管理和五险报表生成等功能的系统。它能帮助企业或人力资源代理机构更高效地管理劳务代理业务，包括员工档案、合同续签、社保费用计算、备案管理和五险报表生成等。通过使用该系统，用户可以提高劳务代理业务的管理效率和准确性，实现更好的业务运营和发展。',
						productUrl: `http://120.48.181.47:8023`,
					},
					{
						productId: 2,
						productThumbnail: home2,
						productName: '劳务派遣',
						productInfo: '劳务派遣是一种灵活的人力资源管理方式，通过与劳务代理机构合作，企业可以将一部分员工的招聘、管理和福利等工作外包出去，从而减轻企业的人力资源负担。劳务派遣系统集成了员工管理、合同管理、社保费用管理、备案管理和五险报表生成等功能，帮助企业更高效地管理劳务派遣业务。通过使用该系统，企业可以提高劳务派遣业务的管理效率和准确性，实现更好的业务运营和发展。',
						productUrl: `http://120.48.181.47:8022`,
					}
				]);

			}
		}
	}, [props.loading]);

	const [autoScroll, setAutoScroll] = useState(true);

	const onClickNumber = (n, i) => {
		clearStyle();
		const dom = document.getElementById(`ps-num-${i}`);
		dom.className = 'ps-num-checked';
		setPsi(i);
		// setAutoScroll(false);
		setAutoScroll((prevAutoScroll) => !prevAutoScroll);
	};

	const onClickProject = (e, url) => {
		window.open(url, '_blank');
	};

	useEffect(() => {
		let timer;
		if (autoScroll) {
			timer = setInterval(() => {
				const nextPsi = psi + 1;
				if (nextPsi === productsList.length) {
					setPsi(0);
					clearStyle();
					const dom = document.getElementById(`ps-num-${0}`);
					dom.className = 'ps-num-checked';
				} else {
					setPsi(nextPsi);
					clearStyle();
					const dom = document.getElementById(`ps-num-${nextPsi}`);
					dom.className = 'ps-num-checked';
				}
			}, 5000);
		}
		return () => clearInterval(timer);
	}, [psi, autoScroll, productsList]);

	return (
		<>
			<div className='products pc-height' id='products'>
				<div className='container pc-padding'>
					<ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut" animateOnce="true" offset={-400} >
						<div className='trims-container'>
							<div className='trims'></div>
							<h1>产品介绍</h1>
						</div>
					</ScrollAnimation>
					<div className='ps-content'>
						<div className='ps-remark'>
							<div className='ps-img'>
								<ScrollAnimation animateIn="slideInRight" animateOut="slideOutLeft" animateOnce>
									<img src={React.api.base_url + productsList[psi]?.productThumbnail} alt={productsList[psi]?.productsName} />
								</ScrollAnimation>
							</div>
							<div>
								<ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut" animateOnce="true" offset={-400} >
									<span className='ps-title'>{props.loading ? productsList[psi]?.productName : productsList[psi]?.productName}</span>
								</ScrollAnimation>
								<ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut" animateOnce="true" offset={-400} delay={300}>
									<div className='ps-body'>{props.loading ? productsList[psi]?.productInfo : productsList[psi]?.productInfo}</div>
									<button className='ps-btn' onClick={(e) => onClickProject(e, productsList[psi]?.productUrl)} >前去查看</button>
								</ScrollAnimation>
							</div>
						</div>
						<div className='ps-number'>
							<ul>
								{
									props.loading ? productsList.map((v, i) => (
										<ScrollAnimation animateIn="fadeInRight" animateOut="fadeOutRight" offset={-400} key={i}>
											<li id={'ps-num-' + i} key={i} onClick={(event) => onClickNumber(event, i)} num={i} className={i === 0 ? 'ps-num-checked' : ''}>{'0' + (i + 1)}</li>
										</ScrollAnimation>
									)) : ''
								}
							</ul>
						</div>
					</div>
				</div >

				{/* 装饰 */}
				<svg className='ps-trims1' viewBox="0 0 1440 320" > <path fill="#0e66fb" d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg >
				<svg className='ps-trims2' viewBox="0 0 1440 320"><path fill="#0e66fb" d="M0,224L48,186.7C96,149,192,75,288,37.3C384,0,480,0,576,16C672,32,768,64,864,90.7C960,117,1056,139,1152,144C1248,149,1344,139,1392,133.3L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
			</div >
		</>
	);
}
