// import React from 'react';
// import request from '../../utils/axios';
import axios from 'axios';

/* PC */
/* 获取首页内容 */
export async function getHomeAll() {
	return await axios.get('https://www.ydzb.org.cn:3023/ydzb_server/SelWebSiteHomePageController/selNoPaging', {
		params: {
			"visitType": "website"
		}
	});
	// return new Promise((resolve, reject) => {
	// 	request('get', '/ydzb/ydzb_server/SelWebSiteHomePageController/selNoPaging').then(res => {
	// 		resolve(res);
	// 	}, error => {
	// 		reject(error);
	// 	});
	// });
}
// 180.76.110.176
// export function getHomeAll2() {
// 	return new Promise((resolve, reject) => {
// 		request('get', `https://www.ydzb.org.cn:3023/ydzb_server/SelWebSiteHomePageController/selNoPaging?visitType=website`).then(res => {
// 			resolve(res);
// 		}, error => {
// 			reject(error);
// 		});
// 	});
// }
// export function getHomeAll3() {
// 	return new Promise((resolve, reject) => {
// 		request('get', `https://www.ydzb.org.cn:3023/ydzb_server/SelWebSiteHomePageController/selNoPaging?visitType=website`).then(res => {
// 			resolve(res);
// 		}, error => {
// 			reject(error);
// 		});
// 	});
// }



/* News */
export async function getNewsDetail(params) {
	return await axios.get('https://www.ydzb.org.cn:3023/ydzb_server/ydzbNewsController/selNewsLimit', {
		params: {
			"visitType": "website",
			...params
		}
	});
	// return new Promise((resolve, reject) => {
	// 	request('get', '/ydzb/ydzb_server/ydzbNewsController/selNewsLimit', params).then(res => {
	// 		resolve(res);
	// 	}, error => {
	// 		reject(error);
	// 	});
	// });
}

/* NewsAll */
export async function getNewsAll() {
	return await axios.get('https://www.ydzb.org.cn:3023/ydzb_server/ydzbNewsController/Sel_news', {
		params: {
			"visitType": "website",
		}
	});
	// return new Promise((resolve, reject) => {
	// 	request('get', '/ydzb/ydzb_server/ydzbNewsController/Sel_news').then(res => {
	// 		resolve(res);
	// 	}, error => {
	// 		reject(error);
	// 	});
	// });
}

/* 平台入口 */
export async function selPlatform() {
	return await axios.get('https://www.ydzb.org.cn:3023/ydzb_server/SelWebSiteHomePageController/selPlatformEntryChildren', {
		params: {
			"visitType": "website",
		}
	});
}
