import React, { useState, useLayoutEffect, useEffect } from 'react';
import './products_app.scss';
import ScrollAnimation from 'react-scroll-animation';
import dl from '../../assets/img/dl.png';
import home1 from '../../assets/img/home1.jpg';
import home2 from '../../assets/img/home2.jpg';

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';
import SwiperCore, { Autoplay } from 'swiper/core';
import { Consumer } from '../../context';

SwiperCore.use([Autoplay]);

export default function Products(props) {

	const [number, setNumber] = useState(0);
	const [productsList, setProductsList] = useState([
		{
			productId: 1,
			productThumbnail: home1,
			productName: '劳务代理',
			productInfo: '劳务代理系统是一个集成了员工管理、合同管理、社保费用管理、备案管理和五险报表生成等功能的系统。它能帮助企业或人力资源代理机构更高效地管理劳务代理业务，包括员工档案、合同续签、社保费用计算、备案管理和五险报表生成等。通过使用该系统，用户可以提高劳务代理业务的管理效率和准确性，实现更好的业务运营和发展。',
			productUrl: `http://120.48.181.47:8023`,
		},
		{
			productId: 2,
			productThumbnail: home2,
			productName: '劳务派遣',
			productInfo: '劳务派遣是一种灵活的人力资源管理方式，通过与劳务代理机构合作，企业可以将一部分员工的招聘、管理和福利等工作外包出去，从而减轻企业的人力资源负担。劳务派遣系统集成了员工管理、合同管理、社保费用管理、备案管理和五险报表生成等功能，帮助企业更高效地管理劳务派遣业务。通过使用该系统，企业可以提高劳务派遣业务的管理效率和准确性，实现更好的业务运营和发展。',
			productUrl: `http://120.48.181.47:8022`,
		}
	]);


	useEffect(() => {
		if (props.loading) {
			if (props.data.data.data?.productsList) {

				const arr = [];
				for (let i = 0; i < props.data.data.data?.productsList.length; i++) {
					const v = props.data.data.data?.productsList[i];
					arr.push(v.content);
				}
				setProductsList(arr);

			} else {
				setProductsList([
					{
						productId: 1,
						productThumbnail: home1,
						productName: '劳务代理',
						productInfo: '劳务代理系统是一个集成了员工管理、合同管理、社保费用管理、备案管理和五险报表生成等功能的系统。它能帮助企业或人力资源代理机构更高效地管理劳务代理业务，包括员工档案、合同续签、社保费用计算、备案管理和五险报表生成等。通过使用该系统，用户可以提高劳务代理业务的管理效率和准确性，实现更好的业务运营和发展。',
						productUrl: `http://120.48.181.47:8023`,
					},
					{
						productId: 2,
						productThumbnail: home2,
						productName: '劳务派遣',
						productInfo: '劳务派遣是一种灵活的人力资源管理方式，通过与劳务代理机构合作，企业可以将一部分员工的招聘、管理和福利等工作外包出去，从而减轻企业的人力资源负担。劳务派遣系统集成了员工管理、合同管理、社保费用管理、备案管理和五险报表生成等功能，帮助企业更高效地管理劳务派遣业务。通过使用该系统，企业可以提高劳务派遣业务的管理效率和准确性，实现更好的业务运营和发展。',
						productUrl: `http://120.48.181.47:8022`,
					}
				]);

			}
		}
	}, [props.loading]);

	const SlideChange = (e) => {
		// console.log(e.activeIndex - 1)
		if (e.activeIndex - 1 === 6) {
			setNumber(0);
		} else if (e.activeIndex - 1 < 0) {
			setNumber(5);
		} else {
			setNumber(e.activeIndex - 1);
		}
	};


	return (
		<>
			<Consumer>
				{
					value =>
						<div className='products_app' id='products_app'>
							<ScrollAnimation animateIn="fadeInUp" offset={-400}>
								<div className='trims_container'>
									<h2>产品介绍</h2>
									<div className='trims_app'></div>
								</div>
							</ScrollAnimation>
							<div className='products_container'>
								<div className="products_img">
									<Swiper
										className="swiper-container"
										spaceBetween={10}
										slidesPerView={1}
										loop={true}
										// autoplay={{ delay: 3000, disableOnInteraction: false }}
										onSlideChange={(e) => SlideChange(e)}
									>
										{
											props.loading ? productsList.map((p) => (
												<SwiperSlide key={p.productId}>
													<img src={React.api.base_url + p.productThumbnail} alt={`Slide ${p.productId}`} />
												</SwiperSlide>
											))
												: productsList.map((p) => (
													<SwiperSlide key={p.productId}>
														<img src={p.productThumbnail} alt={`Slide ${p.productId}`} />
													</SwiperSlide>
												))
										}
									</Swiper>
								</div>
								<div className='products_text'>
									<ScrollAnimation animateIn="fadeInUp" offset={-600}>
										<p className='text_head'>
											<span className='text_title'>{props.loading ? productsList[number].productName : productsList[number].productName}</span>
											<span className='text_icon'></span>
											<span className='text_number'>{'0' + (number + 1)}</span>
										</p>
										<p className='text_body'>{props.loading ? productsList[number].productInfo : productsList[number].productInfo}</p>
									</ScrollAnimation>
								</div>
							</div>
						</div>
				}
			</Consumer>
		</>
	);
}
