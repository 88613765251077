import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './news_particulars_app.scss';
import { getNewsDetail } from '../service';

const getDate = (date) => {
    var date = new Date(date);
    // 获取年、月、日
    var year = date.getFullYear();
    var month = date.getMonth() + 1; // 月份是从0开始的，所以要加1
    var day = date.getDate();

    return year + "-" + month + "-" + day;
};

export default function NewsParticularsApp() {
    const [id, setId] = useState([]);
    let navigate = useNavigate();

    const [now, setnow] = useState();

    function back() {
        // if (id.length == 2) {
        //     navigate(`/?page=news_app`);
        // } else if (id.length == 3) {
        //     const id2 = id[1].split('&');
        //     navigate(`/NewsListApp?id=${id2[0]}`);
        // }
        navigate(`/?page=news_app`);
    }

    function goList() {
        // if (id.length == 3) {
        //     const id2 = id[1].split('&');
        //     navigate(`/NewsListApp?id=${id2[0]}`);
        // } else {
        //     navigate(`/NewsListApp?id=${id[1]}`);
        // }
        navigate(`/NewsListApp`);
    }

    const fetch = async (id) => {
        const res = await getNewsDetail({ ydzb_news_id: id[1] });
        let newsDetail = res.data.data.now[0];
        if (newsDetail) {
            newsDetail.create_time = getDate(newsDetail.create_time);
            setnow(newsDetail);
        } else {
            setnow({
                ydzb_news_id: 0,
                ydzb_news_title: '没有了'
            });
        };
    };
    const [newsId, setNewsId] = useState(Number);

    useEffect(() => {
        if (newsId === 0) {
            const props = window.location.search.split('=');
            setId(props);
            fetch(props);
        }

    }, [newsId]);

    return (
        <>
            <div style={{
                height: '4rem',
            }}></div>
            <div className='news_particulars_app'>
                <div className='tool'>
                    <div className='back' onClick={back}>
                        <svg className='ft-left-svg' t="1698742937327" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13784" width="32" height="32"><path d="M767.7 480.9H207.1l217.7-217.7c12.3-12.3 12.3-32.4 0-44.7l-0.7-0.7c-12.3-12.3-32.4-12.3-44.7 0.1L107 490.3c-12.3 12.3-12.3 32.4 0 44.7l0.6 0.7c0.1 0 0.2 0.1 0.2 0.1l271.1 271.1c12.5 12.5 32.9 12.5 45.4 0s12.5-32.9 0-45.4L206.7 543.9h561c17.3 0 31.5-14.2 31.5-31.5s-14.2-31.5-31.5-31.5z" fill="#43464a" p-id="13785"></path><path d="M864.1 512.6a31.8 31.7 0 1 0 63.6 0 31.8 31.7 0 1 0-63.6 0Z" fill="#43464a" p-id="13786"></path></svg>
                        <p>返回首页</p>
                    </div>
                    <div className='gotoList' onClick={goList}>
                        <p>全部新闻</p>
                        <svg className='ft-right-svg' t="1698743044294" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14927" width="32" height="32"><path d="M918.6 489.3L646.1 216.8c-12.3-12.3-32.5-12.3-44.8 0l-0.7 0.7c-12.3 12.3-12.3 32.4 0 44.7l218.7 218.7H257.6c-17.3 0-31.5 14.2-31.5 31.5s14.2 31.5 31.5 31.5h560.1L601.2 760.5c-12.5 12.5-12.5 32.9 0 45.4s32.9 12.5 45.4 0l271.1-271.1s0.1-0.1 0.2-0.1l0.7-0.7c12.3-12.3 12.3-32.4 0-44.7z" fill="#43464a" p-id="14928"></path><path d="M97.6 512.6a31.8 31.7 0 1 0 63.6 0 31.8 31.7 0 1 0-63.6 0Z" fill="#43464a" p-id="14929"></path></svg>
                    </div>
                </div>
                <div className='p_container'>
                    <div className='p_title'>
                        {now?.ydzb_news_title}
                    </div>
                    <div className='p_time'>
                        {now?.create_time}
                    </div>
                    <div className='p_body' dangerouslySetInnerHTML={{ __html: now?.ydzb_news_content }}>
                    </div>
                </div>
            </div>
        </>
    );
}
