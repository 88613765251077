import React, { useEffect, useState } from 'react';
import './about.scss';
import ScrollAnimation from 'react-scroll-animation';
import ewm from '../../assets/img/ewm.jpg';
import { Consumer } from '../../context';

function Contact(props) {

	// const [contact, setContact] = useState({
	// 	homeConfigEwm: ewm,
	// 	homeConfigEwmText: '微信公众号',
	// 	homeConfigHotline: '110-0101-000',	// 服务热线
	// 	homeConfigPhone: '101-12345678', // 电话
	// 	homeConfigFax: '101-12345678', // 传真电话
	// 	homeConfigEmail: 'ydzb@ydzb.com', // 邮箱
	// 	homeConfigAddress: '山西省阳泉高新技术产业开发区大连街山西智创城NO.7云谷科技创新园C座9层902室', // 地址
	// });

	const [contact, setContact] = useState([{
		homeConfigEwm: ewm,
		homeConfigEwmText: '微信公众号',
		homeConfigHotline: 'xxx-xxxx-xxx',	// 服务热线
		homeConfigPhone: 'xxx-xxx', // 电话
		homeConfigFax: 'xxx-xxx', // 传真电话
		homeConfigEmail: 'xxxxx', // 邮箱
		homeConfigAddress: 'xxxxxx', // 地址
		// 山西省阳泉高新技术产业开发区大连街山西智创城NO.7云谷科技创新园C座9层902室
	}]);

	useEffect(() => {
		if (props.loading) {
			if (props.data.data.data?.basicInfo) {

				const arr = [];
				for (let i = 0; i < props.data.data.data?.basicInfo.length; i++) {
					const v = props.data.data.data?.basicInfo[i];
					arr.push({
						homeConfigEwmText: v.content.homeConfigEwmText,
						homeConfigEwm: v.content.homeConfigEwm,
						homeConfigHotline: v.content.homeConfigHotline,
						homeConfigPhone: v.content.homeConfigPhone,
						homeConfigFax: v.content.homeConfigFax,
						homeConfigEmail: v.content.homeConfigEmail,
						homeConfigAddress: v.content.homeConfigAddress,
					});
				}
				setContact(v => arr);
			} else {
				setContact(v => [{
					homeConfigEwm: ewm,
					homeConfigEwmText: '微信公众号',
					homeConfigHotline: 'xxx-xxxx-xxx',	// 服务热线
					homeConfigPhone: 'xxx-xxx', // 电话
					homeConfigFax: 'xxx-xxx', // 传真电话
					homeConfigEmail: 'xxxxx', // 邮箱
					homeConfigAddress: 'xxxxxx', // 地址
				}]);

			}
		}
	}, [props.loading]);
	
	return (
		<>
			<Consumer>
				{
					value =>
						<div className='contact'>
							<div className='container pc-padding'>
								<div className='ct-content'>
									<div className='ct-wx'>

										<img className='ewm' src={props.loading ? React.api.base_url + contact[0]?.homeConfigEwm : contact.img} alt='ewm' />
										<p className='ct-p'>{contact[0]?.homeConfigEwmText}</p>
									</div>

									<div className='ct-body'>
										<div className='ct-us'>联系我们</div>
										<p>服务热线：{props.loading ? contact[0]?.homeConfigHotline : contact.hotline}</p>
										<p>电话：{props.loading ? contact[0]?.homeConfigPhone : contact.phone}</p>
										<p>传真：{props.loading ? contact[0]?.homeConfigFax : contact.fax}</p>
										<p>邮箱：{props.loading ? contact[0]?.homeConfigEmail : contact.email}</p>
										<p>地址：{props.loading ? contact[0]?.homeConfigAddress : contact.address}</p>
									</div>
								</div>
							</div>
						</div>
				}
			</Consumer>
		</>
	);
}

export default function About(props) {

	const [basicInfo, setBasicInfo] = useState([{
		homeConfigAboutTitle: '',
		homeConfigAboutContent: '',
	}]);
	useEffect(() => {
		if (props.loading) {
			// const data = props.data.data.data?.basicInfo;
			if (props.data.data.data?.basicInfo) {

				const arr = [];
				for (let i = 0; i < props.data.data.data?.basicInfo.length; i++) {
					const v = props.data.data.data?.basicInfo[i];
					arr.push({ homeConfigAboutTitle: v.content.homeConfigAboutTitle, homeConfigAboutContent: v.content.homeConfigAboutContent, saasThumbnail: v.content.saasThumbnail });

				}
				setBasicInfo(v => arr);
			} else {
				setBasicInfo(v => [{
					homeConfigAboutTitle: 'xxxx',
					homeConfigAboutContent: 'xxx',
				}]);

			}
		}
	}, [props.loading]);
	return (
		<>
			<Consumer>
				{
					value =>
						<div className='about pc-height' id='about'>
							<div className='container pc-padding'>
								<ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut" animateOnce="true" offset={-400}  >
									<div className='trims-container'>
										<div className='trims2'></div>
										<h1>关于易道助邦</h1>
									</div>
								</ScrollAnimation>
								<div className='at-content'>
									<ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut" animateOnce="true" offset={-400}  >
										<p className='h4'>{props.loading ? basicInfo[0]?.homeConfigAboutTitle : `易道助邦科技有限公司是一家于2022年创立的新兴企业。我们专注于提供广泛的技术服务和解决方案，以满足客户在不断发展的数字化时代中的需求。`}</p>
									</ScrollAnimation>
									<ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut" animateOnce="true" offset={-400}  >
										<div className='desc'>
											{props.loading ? basicInfo[0]?.homeConfigAboutContent
												: `作为一家技术驱动型公司，我们的经营范围涵盖了技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广等领域。我们拥有一支充满激情和经验丰富的团队，致力于为客户提供创新的解决方案和高质量的服务。
												在软件开发方面，我们专注于人工智能应用软件开发、网络与信息安全软件开发等领域。我们深入了解客户的需求，并结合最新的技术趋势和行业标准，为客户量身定制高效、可靠的软件解决方案。
												此外，我们还提供专业设计服务，包括信息系统运行维护服务、物联网技术服务和企业管理咨询等。我们致力于帮助客户提升运营效率、降低成本，并在竞争激烈的市场中获取竞争优势。
												我们的产品包括劳务代理和劳务派遣。通过与各行各业的企业建立合作关系，我们为客户提供高质量的劳务服务，帮助他们解决人力资源管理方面的挑战。
												易道助邦科技有限公司致力于成为客户信赖的技术合作伙伴。我们通过持续创新和不断提升服务质量，与客户共同实现业务目标，推动数字化转型和技术进步。如果您对我们的公司感兴趣或有任何合作需求，请随时与我们联系。我们期待与您携手合作，共创美好未来。`
											}

										</div>
									</ScrollAnimation>
								</div>
							</div>
						</div>
				}
			</Consumer>
			<Contact {...props} />
		</>
	);
}
