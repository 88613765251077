import React, { useEffect, useState } from 'react';
import './news.scss';
import ScrollAnimation from 'react-scroll-animation';
import { useNavigate } from 'react-router-dom';
import { Consumer } from '../../context';

export default function News(props) {
	let navigate = useNavigate();
	const [newsList, setNewsList] = useState([
		{
			id: 0,
			title: '易道助邦网站上线了！',
			date: '2023-10-01',
			content: 'lorem ipsum dolor si dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit dt amet, consectetur adip',
		},
		{
			id: 1,
			title: '代理·派遣系统',
			date: '2023-10-22',
			content: 'lorem ipsum dolor sit dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit dolor sit dolor sit am',
		},
		{
			id: 2,
			title: '后台管理门户网站',
			date: '2023-10-14',
			content: 'loremlorem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consectetur adiplorem ipsum dolor sit amet, consectetur adip',
		}
	]);
	
	useEffect(() => {
		if (props.loading) {
			if (props.data.data.data?.news)
				setNewsList(v => props.data.data.data?.news);
		}
	}, [props.loading]);
	
	const onClickLink = (e, id) => {
		navigate(`/newsDetail?id=${id}`);
	};
	return (
		<>
			<Consumer>
				{value =>
					<div className='news' id='news'>
						<div className='container'>
							<ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut" animateOnce="true" offset={-400}>
								<div className='trims-container'>
									<div className='trims'></div>
									<h1>最新公告</h1>
								</div>
							</ScrollAnimation>

							<div className='news-content'>
								{props.loading ? newsList.map((v, i) => (
									<ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut" animateOnce="true" offset={-400} delay={i === 0 ? 0 : i * 5000} key={i}>
										<div className={`news${i + 1}`} onClick={(e) => onClickLink(e, v.ydzb_news_id)}>
											<div className='ns-date'>
												<span>{props.loading ? v.create_time ? v.create_time.substring(8, 10) : '' : ''}</span>	{/* .substring(8, 2) */}
												<span>{props.loading ? v.create_time ? v.create_time.substring(0, 7) : '' : ''}</span>	{/* .substring(0, 7) */}
											</div>
											<div className='ns-content'>
												<span>{props.loading ? v.ydzb_news_title : v.title}</span>
												<div>{props.loading ? v.ydzb_news_content : v.content}</div>
											</div>
										</div>
									</ScrollAnimation>
								)) : ''

								}
							</div>
							{/* <div className='news-content'>
								{
									news.map((v, i) => (
										<ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut" animateOnce="true" offset={-400} delay={i === 0 ? 0 : i * 5000} key={v.id}>
											<div className={`news${v.id + 1}`} onClick={(e) => onClickLink(e, v.id)}>
												<div className='ns-date'>
													<span>{v.date.slice(8)}</span>
													<span>{v.date.slice(0, 7).replace('-', '.')}</span>
												</div>
												<div className='ns-content'>
													<span>{v.title}</span>
													<div>{v.content}</div>
												</div>
											</div>
										</ScrollAnimation>
									))
								}
							</div>
							 */}
						</div>
						{/* 装饰 */}
						<svg style={{
							position: 'relative',
							top: '10px',
						}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0e66fb" d="M0,128L30,144C60,160,120,192,180,197.3C240,203,300,181,360,192C420,203,480,245,540,234.7C600,224,660,160,720,160C780,160,840,224,900,224C960,224,1020,160,1080,144C1140,128,1200,160,1260,154.7C1320,149,1380,107,1410,85.3L1440,64L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path></svg>
					</div >
				}
			</Consumer>
		</>
	);
}
