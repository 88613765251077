import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import './newsList_app.scss'
import { getNewsAll } from '../service';
const getDate = (date) => {
    var date = new Date(date);
    // 获取年、月、日
    var year = date.getFullYear();
    var month = date.getMonth() + 1; // 月份是从0开始的，所以要加1
    var day = date.getDate();

    return year + "-" + month + "-" + day;
};
export default function NewsListApp() {
    const [id, setId] = useState([]);

    let navigate = useNavigate();

    const [news, setNews] = useState([
        {
            ydzb_news_id: 0,
            ydzb_news_title: '易道助邦网站上线了！',
            create_time: '2023-10-01',
            ydzb_news_content: 'lorem ipsum dolor si dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit dt amet, consectetur adip',
        },
        {
            ydzb_news_id: 1,
            ydzb_news_title: '代理·派遣系统',
            create_time: '2023-10-22',
            ydzb_news_content: 'lorem ipsum dolor sit dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit dolor sit dolor sit am',
        },
        {
            ydzb_news_id: 2,
            ydzb_news_title: '后台管理门户网站',
            create_time: '2023-10-14',
            ydzb_news_content: 'loremlorem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consectetur adiplorem ipsum dolor sit amet, consectetur adip',
        },
        {
            ydzb_news_id: 0,
            ydzb_news_title: '易道助邦网站上线了！',
            create_time: '2023-10-01',
            ydzb_news_content: 'lorem ipsum dolor si dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit dt amet, consectetur adip',
        },
        {
            ydzb_news_id: 1,
            ydzb_news_title: '代理·派遣系统',
            create_time: '2023-10-22',
            ydzb_news_content: 'lorem ipsum dolor sit dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit dolor sit dolor sit am',
        },
        {
            ydzb_news_id: 2,
            ydzb_news_title: '后台管理门户网站',
            create_time: '2023-10-14',
            ydzb_news_content: 'loremlorem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consectetur adiplorem ipsum dolor sit amet, consectetur adip',
        },
    ]);

    function back() {
        navigate(`/newsParticularsApp?id=${id[1]}`);
    }

    function goHome() {
        navigate(`/?page=news_app`);
    }

    const goNews = (id) => {
        navigate(`/newsParticularsApp?id=${id}`);
    }

    useEffect(() => {
        const props = window.location.search.split('=');
        setId(props)
        
        const fetch = async () => {
            const res = await getNewsAll();
            res.data.data.list.map(v => v.create_time = getDate(v.create_time));
            setNews(res.data.data.list)
        };
        fetch();
    }, [])

    return (
        <>
            <div style={{
                height: '4rem',
            }}></div>
            <div className='news_particulars_app'>
                <div className='tool'>
                    {/* <div className='back' onClick={goHome}>
                        <svg className='ft-left-svg' t="1698742937327" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13784" width="32" height="32"><path d="M767.7 480.9H207.1l217.7-217.7c12.3-12.3 12.3-32.4 0-44.7l-0.7-0.7c-12.3-12.3-32.4-12.3-44.7 0.1L107 490.3c-12.3 12.3-12.3 32.4 0 44.7l0.6 0.7c0.1 0 0.2 0.1 0.2 0.1l271.1 271.1c12.5 12.5 32.9 12.5 45.4 0s12.5-32.9 0-45.4L206.7 543.9h561c17.3 0 31.5-14.2 31.5-31.5s-14.2-31.5-31.5-31.5z" fill="#43464a" p-id="13785"></path><path d="M864.1 512.6a31.8 31.7 0 1 0 63.6 0 31.8 31.7 0 1 0-63.6 0Z" fill="#43464a" p-id="13786"></path></svg>
                        <p>返回首页</p>
                    </div> */}
                    {/* <div className='gotoList' onClick={goHome}>
                        <p>首页</p>
                        <svg className='ft-right-svg' t="1698743044294" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14927" width="32" height="32"><path d="M918.6 489.3L646.1 216.8c-12.3-12.3-32.5-12.3-44.8 0l-0.7 0.7c-12.3 12.3-12.3 32.4 0 44.7l218.7 218.7H257.6c-17.3 0-31.5 14.2-31.5 31.5s14.2 31.5 31.5 31.5h560.1L601.2 760.5c-12.5 12.5-12.5 32.9 0 45.4s32.9 12.5 45.4 0l271.1-271.1s0.1-0.1 0.2-0.1l0.7-0.7c12.3-12.3 12.3-32.4 0-44.7z" fill="#43464a" p-id="14928"></path><path d="M97.6 512.6a31.8 31.7 0 1 0 63.6 0 31.8 31.7 0 1 0-63.6 0Z" fill="#43464a" p-id="14929"></path></svg>
                    </div> */}
                </div>
                <div className='nList_container'>
                    {
                        news.map((v, i) => (
                            <div className='nList_card' key={i} onClick={() => goNews(v.ydzb_news_id)}>
                                <div className="nList_card_title">
                                    <p>{v.ydzb_news_title}</p>
                                    <p className='date'>{v.create_time}</p>
                                </div>
                                <div className="nList_card_content">
                                    <span>{v.ydzb_news_content}</span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}
