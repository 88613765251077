import React, { useState, useEffect } from 'react'
import './header_app.scss'
import logo from '../../logo.svg'
import more from '../../assets/img/more.svg'
import { Drawer } from 'antd';
import { useNavigate } from 'react-router-dom';



export default function HeaderApp() {

    const [scroolName, setScroolName] = useState('');
    const [open, setOpen] = useState(false);
    let navigate = useNavigate();


    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const active = (e, id, id2) => {
        setOpen(false);

        let classList = document.getElementsByClassName('item_active');
        for (const item in classList) {
            if (Object.hasOwnProperty.call(classList, item)) {
                const element = classList[item];
                element.classList.remove('item_active');
            }
        }

        const dom = document.getElementById(id);
        dom.className = 'item item_active';

        const dom2 = document.getElementById(id2);

        const pageName = e.currentTarget.getAttribute('name');

        if (window.location.pathname !== '/') {
            navigate(`/?page=${pageName}`);
        } else {
            window.scrollTo({
                top: dom2.offsetTop - 60,
                behavior: 'smooth'
            });
        }
    }

    function goHome() {
        navigate(`/?page=news_app`);
    }
    useEffect(() => {
        const backName = window.location.search.split('=');
        // console.log(backName[0], "-----")
        if (backName[0] !== '') {
            setScroolName(backName)
            const dom = document.getElementById(scroolName);
            // window.scrollTo({
            //     top: dom.offsetTop - 60,
            //     behavior: 'smooth'
            // });
        }
    }, [scroolName])
    return (
        <>
            <div className='header_app'>
                <div className='header_app_logo'>
                    <div className='logo' onClick={goHome}>
                        <img src={logo} alt="" />
                        <p>易道助邦</p>
                    </div>
                    <div className="logo_text">
                        <img src={more} alt="" onClick={showDrawer} />
                        <Drawer title="MENU" placement="right" onClose={onClose} open={open}>
                            <ul>
                                <li><div onClick={(e) => active(e, 'home-li', 'home_app')} name="home_app" id='home-li' className='item itemTop item_active'>首页</div></li>
                                <li><div onClick={(e) => active(e, 'products-li', 'products_app')} name="products_app" id='products-li' className='item'>产品介绍</div></li>
                                <li><div onClick={(e) => active(e, 'news-li', 'news_app')} name="news_app" id='news-li' className='item'>新闻公告</div></li>
                                <li><div onClick={(e) => active(e, 'about-li', 'about_app')} name="about_app" id='about-li' className='item'>关于我们</div></li>
                            </ul>
                        </Drawer>
                    </div>
                </div>
            </div>
        </>
    )
}
