import React, { Component } from 'react';
import './footer_app.scss';

export default class FooterApp extends Component {
	render() {
		return (
			<>
				<div className='footer_app'>
					<span>@易道助邦 2022</span>
					<span>晋ICP备88888888号</span>
				</div>
			</>
		);
	}
}
