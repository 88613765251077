import React, { Component } from 'react';
import './footer.scss';

export default class Footer extends Component {
	render() {
		return (
			<>
				<div className='footer'>
					{/* https://beian.miit.gov.cn/#/Integrated/index */}
					<p>@山西易道助邦科技有限公司 2023  <span onClick={()=>window.open('https://beian.miit.gov.cn/#/Integrated/index')}>晋ICP备2023015007号-4</span></p>
				</div>
			</>
		);
	}
}
