import React, { useEffect, useState } from 'react';
import './platform.scss';
import platformImg from '../../assets/img/platform.png';
import { selPlatform } from '../service';

import p1 from '../../assets/icons/p1.svg';
import p2 from '../../assets/icons/p2.svg';
import p3 from '../../assets/icons/p3.svg';
import p4 from '../../assets/icons/p4.svg';
import { Image, Modal } from 'antd';

export default function Platform(props) {

	const [platform1, setPlatform1] = useState([]);
	const [platform2, setPlatform2] = useState([]);
	const [platform3, setPlatform3] = useState([]);
	const [platform4, setPlatform4] = useState([]);
	const [open, setOpen] = useState(false);
	const [url, setUrl] = useState('');


	useEffect(() => {
		const fetch = async () => {
			const res = await selPlatform();
			if (res.data.data.searchHits.length > 0) {
				const pp = res.data.data.searchHits;
				const arr1 = [], arr2 = [], arr3 = [], arr4 = [];
				for (let i = 0; i < pp.length; i++) {
					const element = pp[i];
					if (element.content.platformEntryChildrenIconType === 'svg') {
						console.log('🚀 ~ fetch ~ element.content:', element.content);
						const str = element.content.platformEntryChildrenIcon.replace('+xml', '');
						element.content.platformEntryChildrenIcon = str;
					}
					if (element.content.platformEntryParentType === '企业用户') {
						arr1.push(element.content);
					}
					if (element.content.platformEntryParentType === '员工入口') {
						arr2.push(element.content);
					}
					if (element.content.platformEntryParentType === '人力资源集团') {
						arr3.push(element.content);
					}
					if (element.content.platformEntryParentType === '生态合作伙伴') {
						arr4.push(element.content);
					}
				}
				setPlatform1(v => arr1);
				setPlatform2(v => arr2);
				setPlatform3(v => arr3);
				setPlatform4(v => arr4);
			}

			props.onSetFalseLoading();
		};
		fetch();
	}, []);

	const onClickPlatform = (data) => {
		console.log('🚀 ~ onClickPlatform ~ data:', data);
		if (data.platformEntryChildrenType === '浏览器端') {
			window.open(data.platformEntryChildrenUrl);
		} else {
			console.log('ewm');
			setUrl(v => React.api.base_url + data.platformEntryChildrenEwm);
			setOpen(true);
		}

	};


	return (
		<>
			<div style={{
				height: '80px',
			}}></div>

			<Modal
				centered
				visible={open}
				onOk={() => setOpen(false)}
				onCancel={() => setOpen(false)}
				footer={[]}
				closeIcon={false}
				width="auto"
			>
				<Image width={300} height={300} src={url} alt='EWM' preview={false} />
			</Modal>
			<div className='platform'>
				<img className='pl-img' src={platformImg} alt='platform' />
				<div className='pf-container container pc-padding'>
					<div className='pf-background'>

						<div className='pf-list'>
							<div className='ls-header'>
								<img className='ls-left' src={p1} alt='icon' />
								<div className='ls-right'>
									<p className='title'>企业用户</p>
									<p className='text'>数字化服务生态系统</p>
								</div>
							</div>
							<div className='ls-content'>
								{
									platform1.map((v, i) => (
										<div className='ct-content' key={`p1${i}`} onClick={() => onClickPlatform(v)}>
											{
												v.platformEntryChildrenType === 'svg' ?
													(<object data={React.api.base_url + v.platformEntryChildrenIcon} className='ct-img'></object>)
													: (<img className='ct-img' src={React.api.base_url + v.platformEntryChildrenIcon} alt='icon' />)
											}
											<p className='ct-text'>{v.platformEntryChildrenTitle}</p>
										</div>
									))
								}
							</div>
						</div>
						<div className='pf-list'>
							<div className='ls-header'>
								<img className='ls-left' src={p2} alt='icon' />
								<div className='ls-right'>
									<p className='title'>企业用户</p>
									<p className='text'>数字化服务生态系统</p>
								</div>
							</div>
							<div className='ls-content'>
								{
									platform2.map((v, i) => (
										<div className='ct-content' key={`p2${i}`} onClick={() => onClickPlatform(v)}>
											<img className='ct-img' src={React.api.base_url + v.platformEntryChildrenIcon} alt='icon' />
											<p className='ct-text'>{v.platformEntryChildrenTitle}</p>
										</div>
									))
								}
							</div>
						</div>
						<div className='pf-list'>
							<div className='ls-header'>
								<img className='ls-left' src={p3} alt='icon' />
								<div className='ls-right'>
									<p className='title'>企业用户</p>
									<p className='text'>数字化服务生态系统</p>
								</div>
							</div>
							<div className='ls-content'>
								{
									platform3.map((v, i) => (
										<div className='ct-content' key={`p3${i}`} onClick={() => onClickPlatform(v)}>
											<img className='ct-img' src={React.api.base_url + v.platformEntryChildrenIcon} alt='icon' />
											<p className='ct-text'>{v.platformEntryChildrenTitle}</p>
										</div>
									))
								}
							</div>
						</div>
						<div className='pf-list'>
							<div className='ls-header'>
								<img className='ls-left' src={p4} alt='icon' />
								<div className='ls-right'>
									<p className='title'>企业用户</p>
									<p className='text'>数字化服务生态系统</p>
								</div>
							</div>
							<div className='ls-content'>
								{
									platform4.map((v, i) => (
										<div className='ct-content' key={`p4${i}`} onClick={() => onClickPlatform(v)}>
											<img className='ct-img' src={React.api.base_url + v.platformEntryChildrenIcon} alt='icon' />
											<p className='ct-text'>{v.platformEntryChildrenTitle}</p>
										</div>
									))
								}
							</div>
						</div>

					</div>
				</div>
			</div>
		</>
	);
}
