import React, { useState } from 'react';
import './newsDetail.scss';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getNewsDetail } from '../service';
import axios from 'axios';
import { Alert } from 'antd';

const getDate = (date) => {
	var date = new Date(date);
	// 获取年、月、日
	var year = date.getFullYear();
	var month = date.getMonth() + 1; // 月份是从0开始的，所以要加1
	var day = date.getDate();

	return year + "-" + month + "-" + day;
};

export default function NewsDetail(props) {
	let navigate = useNavigate();

	const fetch = async (id) => {

		const res = await getNewsDetail({ ydzb_news_id: id });
		let newsList = res.data.data.before[0];
		if (newsList) {
			newsList.create_time = getDate(newsList.create_time);
			setbefore(newsList);
		} else {
			setbefore({
				ydzb_news_id: 0,
				ydzb_news_title: ''
			});
		};
		let newsDetail = res.data.data.now[0];
		if (newsDetail) {
			newsDetail.create_time = getDate(newsDetail.create_time);
			setnow(newsDetail);
		} else {
			setnow({
				ydzb_news_id: 0,
				ydzb_news_title: ''
			});
		};
		let newsformnowon = res.data.data.fromnowon[0];
		if (newsformnowon) {
			newsformnowon.create_time = getDate(newsformnowon.create_time);
			setfromnowon(newsformnowon);
		} else {
			setfromnowon({
				ydzb_news_id: 0,
				ydzb_news_title: ''
			});
		};
	};
	//过去
	const [before, setbefore] = useState();
	//现在
	const [now, setnow] = useState();
	//未来
	const [fromnowon, setfromnowon] = useState();

	const [newsId, setNewsId] = useState(Number);

	const onClickBack = () => {
		navigate(`/?page=news`);
	};
	const onClickNewsList = () => {
		navigate(`/newsList`);
	};

	const onClickChangeNews = (e, id) => {
		if (id !== 0) {
			fetch(id);
			navigate(`/newsDetail?id=${id}`);
		}
	};
	useEffect(() => {
		if (newsId === 0) {
			const [, id] = window.location.search.split('=');
			fetch(id);
			props.onSetFalseLoading();
		}
	}, [newsId]);

	return (
		<>
			<div style={{
				height: '80px',
			}}></div>

			<div className='newsDetail'>
				<div className='container pc-padding'>
					<div className='nl-header'>
						<div className='hd-left' onClick={onClickBack}>
							<svg className='hd-left-svg' t="1698742937327" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13784" width="32" height="32"><path d="M767.7 480.9H207.1l217.7-217.7c12.3-12.3 12.3-32.4 0-44.7l-0.7-0.7c-12.3-12.3-32.4-12.3-44.7 0.1L107 490.3c-12.3 12.3-12.3 32.4 0 44.7l0.6 0.7c0.1 0 0.2 0.1 0.2 0.1l271.1 271.1c12.5 12.5 32.9 12.5 45.4 0s12.5-32.9 0-45.4L206.7 543.9h561c17.3 0 31.5-14.2 31.5-31.5s-14.2-31.5-31.5-31.5z" fill="#43464a" p-id="13785"></path><path d="M864.1 512.6a31.8 31.7 0 1 0 63.6 0 31.8 31.7 0 1 0-63.6 0Z" fill="#43464a" p-id="13786"></path></svg>
							<p className='hd-left-p'>返回首页</p>
						</div>
						<div className='hd-right' onClick={onClickNewsList}>
							<p className='hd-right-p'>查看更多公告</p>
							<svg className='hd-right-svg' t="1698743044294" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14927" width="32" height="32"><path d="M918.6 489.3L646.1 216.8c-12.3-12.3-32.5-12.3-44.8 0l-0.7 0.7c-12.3 12.3-12.3 32.4 0 44.7l218.7 218.7H257.6c-17.3 0-31.5 14.2-31.5 31.5s14.2 31.5 31.5 31.5h560.1L601.2 760.5c-12.5 12.5-12.5 32.9 0 45.4s32.9 12.5 45.4 0l271.1-271.1s0.1-0.1 0.2-0.1l0.7-0.7c12.3-12.3 12.3-32.4 0-44.7z" fill="#43464a" p-id="14928"></path><path d="M97.6 512.6a31.8 31.7 0 1 0 63.6 0 31.8 31.7 0 1 0-63.6 0Z" fill="#43464a" p-id="14929"></path></svg>
						</div>
					</div>
					<div className='nl-content'>
						<p className='ct-title'>{now?.ydzb_news_title}</p>
						<p className='ct-timer'>{now?.create_time}</p>
						<div className='ct-body' dangerouslySetInnerHTML={{ __html: now?.ydzb_news_content }}></div>
					</div>
					<div className='nl-footer'>
						<div className='ft-left' onClick={(e) => onClickChangeNews(e, before?.ydzb_news_id)}>
							<svg className='ft-left-svg' t="1698742937327" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13784" width="32" height="32"><path d="M767.7 480.9H207.1l217.7-217.7c12.3-12.3 12.3-32.4 0-44.7l-0.7-0.7c-12.3-12.3-32.4-12.3-44.7 0.1L107 490.3c-12.3 12.3-12.3 32.4 0 44.7l0.6 0.7c0.1 0 0.2 0.1 0.2 0.1l271.1 271.1c12.5 12.5 32.9 12.5 45.4 0s12.5-32.9 0-45.4L206.7 543.9h561c17.3 0 31.5-14.2 31.5-31.5s-14.2-31.5-31.5-31.5z" fill="#43464a" p-id="13785"></path><path d="M864.1 512.6a31.8 31.7 0 1 0 63.6 0 31.8 31.7 0 1 0-63.6 0Z" fill="#43464a" p-id="13786"></path></svg>
							<p className='ft-left-p'>上一篇</p>
							<span className='ft-left-title'>{before?.ydzb_news_title}</span>
						</div>
						<div className='ft-right' onClick={(e) => onClickChangeNews(e, fromnowon?.ydzb_news_id)}>
							<span className='ft-right-title'>{fromnowon?.ydzb_news_title}</span>
							<p className='ft-right-p'>下一篇</p>
							<svg className='ft-right-svg' t="1698743044294" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14927" width="32" height="32"><path d="M918.6 489.3L646.1 216.8c-12.3-12.3-32.5-12.3-44.8 0l-0.7 0.7c-12.3 12.3-12.3 32.4 0 44.7l218.7 218.7H257.6c-17.3 0-31.5 14.2-31.5 31.5s14.2 31.5 31.5 31.5h560.1L601.2 760.5c-12.5 12.5-12.5 32.9 0 45.4s32.9 12.5 45.4 0l271.1-271.1s0.1-0.1 0.2-0.1l0.7-0.7c12.3-12.3 12.3-32.4 0-44.7z" fill="#43464a" p-id="14928"></path><path d="M97.6 512.6a31.8 31.7 0 1 0 63.6 0 31.8 31.7 0 1 0-63.6 0Z" fill="#43464a" p-id="14929"></path></svg>
						</div>
					</div>
				</div>
				<svg className='nl-trims' viewBox="0 0 1440 320"><path fill="#0e66fb" d="M0,256L0,192L46.5,192L46.5,32L92.9,32L92.9,192L139.4,192L139.4,128L185.8,128L185.8,96L232.3,96L232.3,64L278.7,64L278.7,192L325.2,192L325.2,64L371.6,64L371.6,192L418.1,192L418.1,256L464.5,256L464.5,224L511,224L511,96L557.4,96L557.4,128L603.9,128L603.9,288L650.3,288L650.3,192L696.8,192L696.8,224L743.2,224L743.2,256L789.7,256L789.7,64L836.1,64L836.1,96L882.6,96L882.6,192L929,192L929,288L975.5,288L975.5,96L1021.9,96L1021.9,256L1068.4,256L1068.4,32L1114.8,32L1114.8,256L1161.3,256L1161.3,64L1207.7,64L1207.7,64L1254.2,64L1254.2,128L1300.6,128L1300.6,96L1347.1,96L1347.1,32L1393.5,32L1393.5,224L1440,224L1440,320L1393.5,320L1393.5,320L1347.1,320L1347.1,320L1300.6,320L1300.6,320L1254.2,320L1254.2,320L1207.7,320L1207.7,320L1161.3,320L1161.3,320L1114.8,320L1114.8,320L1068.4,320L1068.4,320L1021.9,320L1021.9,320L975.5,320L975.5,320L929,320L929,320L882.6,320L882.6,320L836.1,320L836.1,320L789.7,320L789.7,320L743.2,320L743.2,320L696.8,320L696.8,320L650.3,320L650.3,320L603.9,320L603.9,320L557.4,320L557.4,320L511,320L511,320L464.5,320L464.5,320L418.1,320L418.1,320L371.6,320L371.6,320L325.2,320L325.2,320L278.7,320L278.7,320L232.3,320L232.3,320L185.8,320L185.8,320L139.4,320L139.4,320L92.9,320L92.9,320L46.5,320L46.5,320L0,320L0,320Z"></path></svg>
			</div>
			{/* <div className='newsDetail'>
				<div className='container pc-padding'>
					<div className='nl-header'>
						<div className='hd-left' onClick={onClickBack}>
							<svg className='hd-left-svg' t="1698742937327" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13784" width="32" height="32"><path d="M767.7 480.9H207.1l217.7-217.7c12.3-12.3 12.3-32.4 0-44.7l-0.7-0.7c-12.3-12.3-32.4-12.3-44.7 0.1L107 490.3c-12.3 12.3-12.3 32.4 0 44.7l0.6 0.7c0.1 0 0.2 0.1 0.2 0.1l271.1 271.1c12.5 12.5 32.9 12.5 45.4 0s12.5-32.9 0-45.4L206.7 543.9h561c17.3 0 31.5-14.2 31.5-31.5s-14.2-31.5-31.5-31.5z" fill="#43464a" p-id="13785"></path><path d="M864.1 512.6a31.8 31.7 0 1 0 63.6 0 31.8 31.7 0 1 0-63.6 0Z" fill="#43464a" p-id="13786"></path></svg>
							<p className='hd-left-p'>返回</p>
						</div>
						<div className='hd-right' onClick={onClickNewsList}>
							<p className='hd-right-p'>查看更多公告</p>
							<svg className='hd-right-svg' t="1698743044294" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14927" width="32" height="32"><path d="M918.6 489.3L646.1 216.8c-12.3-12.3-32.5-12.3-44.8 0l-0.7 0.7c-12.3 12.3-12.3 32.4 0 44.7l218.7 218.7H257.6c-17.3 0-31.5 14.2-31.5 31.5s14.2 31.5 31.5 31.5h560.1L601.2 760.5c-12.5 12.5-12.5 32.9 0 45.4s32.9 12.5 45.4 0l271.1-271.1s0.1-0.1 0.2-0.1l0.7-0.7c12.3-12.3 12.3-32.4 0-44.7z" fill="#43464a" p-id="14928"></path><path d="M97.6 512.6a31.8 31.7 0 1 0 63.6 0 31.8 31.7 0 1 0-63.6 0Z" fill="#43464a" p-id="14929"></path></svg>
						</div>
					</div>
					<div className='nl-content'>
						<p className='ct-title'>{NewsArr[1].title}</p>
						<p className='ct-timer'>{NewsArr[1].time}</p>
						<div className='ct-body' dangerouslySetInnerHTML={{ __html: NewsArr[1].content }}></div>
					</div>
					<div className='nl-footer'>
						<div className='ft-left' onClick={(e) => onClickChangeNews(e, NewsArr[0].id)}>
							<svg className='ft-left-svg' t="1698742937327" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13784" width="32" height="32"><path d="M767.7 480.9H207.1l217.7-217.7c12.3-12.3 12.3-32.4 0-44.7l-0.7-0.7c-12.3-12.3-32.4-12.3-44.7 0.1L107 490.3c-12.3 12.3-12.3 32.4 0 44.7l0.6 0.7c0.1 0 0.2 0.1 0.2 0.1l271.1 271.1c12.5 12.5 32.9 12.5 45.4 0s12.5-32.9 0-45.4L206.7 543.9h561c17.3 0 31.5-14.2 31.5-31.5s-14.2-31.5-31.5-31.5z" fill="#43464a" p-id="13785"></path><path d="M864.1 512.6a31.8 31.7 0 1 0 63.6 0 31.8 31.7 0 1 0-63.6 0Z" fill="#43464a" p-id="13786"></path></svg>
							<p className='ft-left-p'>上一篇</p>
							<span className='ft-left-title'>{NewsArr[0].title}</span>
						</div>
						<div className='ft-right' onClick={(e) => onClickChangeNews(e, NewsArr[2].id)}>
							<span className='ft-right-title'>{NewsArr[2].title}</span>
							<p className='ft-right-p'>下一篇</p>
							<svg className='ft-right-svg' t="1698743044294" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14927" width="32" height="32"><path d="M918.6 489.3L646.1 216.8c-12.3-12.3-32.5-12.3-44.8 0l-0.7 0.7c-12.3 12.3-12.3 32.4 0 44.7l218.7 218.7H257.6c-17.3 0-31.5 14.2-31.5 31.5s14.2 31.5 31.5 31.5h560.1L601.2 760.5c-12.5 12.5-12.5 32.9 0 45.4s32.9 12.5 45.4 0l271.1-271.1s0.1-0.1 0.2-0.1l0.7-0.7c12.3-12.3 12.3-32.4 0-44.7z" fill="#43464a" p-id="14928"></path><path d="M97.6 512.6a31.8 31.7 0 1 0 63.6 0 31.8 31.7 0 1 0-63.6 0Z" fill="#43464a" p-id="14929"></path></svg>
						</div>
					</div>
				</div>
				<svg className='nl-trims' viewBox="0 0 1440 320"><path fill="#0e66fb" d="M0,256L0,192L46.5,192L46.5,32L92.9,32L92.9,192L139.4,192L139.4,128L185.8,128L185.8,96L232.3,96L232.3,64L278.7,64L278.7,192L325.2,192L325.2,64L371.6,64L371.6,192L418.1,192L418.1,256L464.5,256L464.5,224L511,224L511,96L557.4,96L557.4,128L603.9,128L603.9,288L650.3,288L650.3,192L696.8,192L696.8,224L743.2,224L743.2,256L789.7,256L789.7,64L836.1,64L836.1,96L882.6,96L882.6,192L929,192L929,288L975.5,288L975.5,96L1021.9,96L1021.9,256L1068.4,256L1068.4,32L1114.8,32L1114.8,256L1161.3,256L1161.3,64L1207.7,64L1207.7,64L1254.2,64L1254.2,128L1300.6,128L1300.6,96L1347.1,96L1347.1,32L1393.5,32L1393.5,224L1440,224L1440,320L1393.5,320L1393.5,320L1347.1,320L1347.1,320L1300.6,320L1300.6,320L1254.2,320L1254.2,320L1207.7,320L1207.7,320L1161.3,320L1161.3,320L1114.8,320L1114.8,320L1068.4,320L1068.4,320L1021.9,320L1021.9,320L975.5,320L975.5,320L929,320L929,320L882.6,320L882.6,320L836.1,320L836.1,320L789.7,320L789.7,320L743.2,320L743.2,320L696.8,320L696.8,320L650.3,320L650.3,320L603.9,320L603.9,320L557.4,320L557.4,320L511,320L511,320L464.5,320L464.5,320L418.1,320L418.1,320L371.6,320L371.6,320L325.2,320L325.2,320L278.7,320L278.7,320L232.3,320L232.3,320L185.8,320L185.8,320L139.4,320L139.4,320L92.9,320L92.9,320L46.5,320L46.5,320L0,320L0,320Z"></path></svg>
			</div> */}
		</>
	);
}
