import React, { useEffect, useState } from 'react';
import './newsList.scss';
import { useNavigate } from 'react-router-dom';
import { getNewsAll } from '../service';

export default function NewsList(props) {

	const [allNews, setAllNews] = useState([
		{
			date: '2023',
			newsList: [
				{
					id: 0,
					ydzb_news_title: '易道助邦网站上线了！',
					date: '2023-10-01',
					content: 'lorem ipsum dolor si dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit dt amet, consectetur adip',
				},
				{
					id: 1,
					ydzb_news_title: '代理·派遣系统',
					date: '2023-10-22',
					content: 'lorem ipsum dolor sit dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit dolor sit dolor sit am',
				},
				{
					id: 2,
					ydzb_news_title: '后台管理门户网站',
					date: '2023-10-14',
					content: 'loremlorem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consectetur adiplorem ipsum dolor sit amet, consectetur adip',
				}
			]
		},
		{
			date: '2022',
			newsList: [
				{
					id: 0,
					ydzb_news_title: '易道助邦网站上线了！',
					date: '2023-10-01',
					content: 'lorem ipsum dolor si dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit dt amet, consectetur adip',
				},
				{
					id: 1,
					ydzb_news_title: '代理·派遣系统',
					date: '2023-10-22',
					content: 'lorem ipsum dolor sit dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit d dolor sit dolor sit dolor sit dolor sit am',
				},
				{
					id: 2,
					ydzb_news_title: '后台管理门户网站',
					date: '2023-10-14',
					content: 'loremlorem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consem ipsum dolor sit amet, consectetur adiplorem ipsum dolor sit amet, consectetur adip',
				}
			]
		}
	]);
	let navigate = useNavigate();

	const onClickLink = (e, id) => {
		navigate(`/newsDetail?id=${id}`);
	};

	useEffect(() => {
		const fetch = async () => {
			const res = await getNewsAll();
			let time = [];
			let arr = [];
			res.data.data.list.map(v => time.push(v.create_time.substr(0, 4)));
			time = Array.from(new Set(time));
			time.map(v => arr.push({ date: v, newsList: [] }));
			res.data.data.list.map(v => {
				let t = v.create_time.substr(0, 4);
				v.date = v.create_time.substr(0, 10);
				let num = time.indexOf(t);
				arr[num].newsList.push(v);
			});
			setAllNews(arr);
			props.onSetFalseLoading();
		};
		fetch();
	}, []);

	return (
		<>
			<div style={{
				height: '80px',
			}}></div>
			<div className='newsList'>

				<div className='container'>
					{
						allNews.map((v, i) => (
							<div className='nsl-content' key={i}>
								<div className='nsl-header'>
									<span className='hd-year'><h4>{v.date}</h4></span>
									<span className='hd-title'><h4>年度公告</h4></span>
								</div>
								<div className='nsl-body'>
									{
										v.newsList.map((n, ni) => (
											<div className='bd-block' onClick={(e) => onClickLink(e, n.ydzb_news_id)} key={ni}>
												<div className='bk-date'>{n.date.replace('-', '.').replace('-', '.')}</div>
												<div className='bk-title'><h5>{n.ydzb_news_title}</h5></div>
											</div>
										))
									}
								</div>
							</div>
						))
					}
				</div>
			</div >
		</>
	);
}
